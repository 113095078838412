/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable dot-notation */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
// eslint-disable-next-line import/no-unresolved
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import AutoGroupHeader from './Templates/AutoGroupHeader/AutoGroupHeader';
// eslint-disable-next-line no-unused-vars
import { isVerticalScrollDisplayed } from './helpers';
// eslint-disable-next-line import/extensions
import config from './ag-config.properties.json';
import './ag-theme-op2mise-2.css';
import './ag-theme-op2mise.css';
import ColumnWithClearFilter from './Templates/ColumnWithClearFilter';

export function DataGridMain(props) {
  const {
    applyCellClassMethod,
    autoGroupColumnDef,
    autoGroupColumnDefinition,
    setAutoGroupColumnDefinition,
    columns,
    columnDefinition,
    defaultColumnProperties,
    disableHighlightOnBookedColumn,
    enableMultipleColumnSorting,
    enableMultipleRowSelection,
    gridHeightFromRoot,
    gridHeightBuffer,
    gridIsLoading,
    handleCapitalization,
    handleChildColumnHeaders,
    isMainGridReady,
    loading,
    mainGridRef,
    mainGridRestProps,
    noRowsOverlayComponent,
    onGridReady,
    onRowDataUpdated,
    onSelectionChanged,
    overviewGridRef,
    pageSize,
    pagination,
    rowData,
    rowClassRules,
    setColumnDefinition,
    setGridHeightFromRoot,
    setGridIsLoading,
    setIsMainGridReady,
    setIsVerticalScroll,
    setRowsToDisplay,
    sharedColumnProperties,
    showAlternativeRowHighlight,
    suppressMovableColumns,
    suppressRowClickSelection,
    useCheckboxForRowSelection,
  } = props;

  const { grid } = config;

  const [columnIsWithChildren, setColumnIsWithChildren] = React.useState(false);
  const [gridHasVerticalWeekColumns, setGridHasVerticalWeekColumns] = React.useState(false);

  const dataGridStyle = React.useMemo(() => ({
    height: `calc(100vh - ${gridHeightBuffer}px)`,
    // height: '100%',
    width: '100%',
    boxShadow: '0 10px 20px rgba(0,0,0,.1)',
    position: 'relative',
  }), [gridHeightFromRoot]);

  const rowClassRulesMemo = React.useMemo(() => ({
    ...rowClassRules,
    ...(showAlternativeRowHighlight && {
      'ag-row-alternate': (params) => params && params.rowIndex % 2 !== 0,
    }),
  }), [rowClassRules, showAlternativeRowHighlight]);

  const handleBooked = React.useCallback((columnInstance) =>
    /**
     * On initial load, this function maps through the list of columns to identify a column with a field or name resembling 'Booked'.
     * If such column is found, it utilizes the applyCellClassMethod function to apply necessary cell styles for cells within the 'Booked' column.
     * The cell styles basically applies two shades of gray (light and dark) as well as auto text alignment to `right`.
     */
    columnInstance.map((column) => {
      if (column.children && column.children.length) {
        setColumnIsWithChildren(true);
        column.children.map((child) => {
          if (child.field.includes('book') && !disableHighlightOnBookedColumn) {
            child['cellStyle'] = applyCellClassMethod;
          }
          return child;
        });
      }
      return column;
    }), [disableHighlightOnBookedColumn, applyCellClassMethod]);

  const handleSharedColumnProperties = React.useCallback((columnInstance) =>
    /**
     * This function is designed to identify and collect shared properties among columns in an ag-Grid configuration.
     * It iterates through the apply properties across multiple columns.
     * This is useful for optimizing column configurations and ensuring consistency in the application's data presentation.
     */
    columnInstance.map((column) => {
      if (sharedColumnProperties) {
        Object.keys(sharedColumnProperties).forEach((key) => {
          if (column.children && column.children.length) {
            column.children.map((child) => {
              if (sharedColumnProperties[key].fields.includes(child.field) && sharedColumnProperties) {
                child[key] = sharedColumnProperties[key].value;
              }
              return child;
            });
          }
          if (sharedColumnProperties[key].fields.includes(column.field) && sharedColumnProperties) {
            column[key] = sharedColumnProperties[key].value;
          }
        });
      }
      return column;
    }), [sharedColumnProperties]);

  const handleColumnHeaderTooltips = React.useCallback(
    (columnInstance) =>
    /**
      * This function improves user experience by adding helpful tooltips to column headers in an ag-Grid setup.
      * It goes through each column, collects useful properties, and ensures they're applied consistently.
      * These tooltips make it easier to understand the displayed data and enhance the setup for better usability.
      * Columns with a field value equal to 'action' will not have an applied headerToolTip value to prevent empty
      * tooltips from showing when hovering over action column header.
      */
      columnInstance.map((column) => {
        if (column.children && column.children.length) {
          column.children.map((child) => {
            child['headerTooltip'] = child.headerName.toUpperCase() || child.field.toUpperCase();
            if (!child.hasOwnProperty('tooltipValueGetter')) {
              child['tooltipValueGetter'] = (params) => params.value;
            }
            return child;
          });
        }
        if (column.field !== 'action') {
          column['headerTooltip'] = column.headerName.toUpperCase() || column.field.toUpperCase();
        }
        if (!column.hasOwnProperty('tooltipValueGetter')) {
          column['tooltipValueGetter'] = (params) => params.value;
        }
        return column;
      }),
    [],
  );

  const handleSetClearFilterAtLastColumn = React.useCallback(
    /**
    * This function is designed to add a clear filter button to the last column in an ag-Grid configuration.
    * It iterates through the columns and applies a custom header component to the last column.
    * This is useful for enhancing the user experience by providing a consistent way to clear filters directly
    * from the column header, improving data interaction and visibility.
    */
    (columnInstance) =>
      columnInstance.map((column, columnIndex) => {
        if (columnInstance.length === columnIndex + 1) {
          return {
            ...column,
            headerComponent: (params) => ColumnWithClearFilter(params),
          };
        }
        return column;
      }),
    [],
  );

  const handleColumnFilterParams = React.useCallback(
    /**
    * This function is designed to configure filter parameters for each column in an ag-Grid configuration.
    * It iterates through the columns and applies specific filter parameters, such as enabling the mini filter to
    * apply while typing and displaying tooltips. This enhances the user experience by providing real-time
    * filtering feedback and additional information through tooltips, making data filtering more intuitive and
    * efficient.
    */
    (columnInstance) =>
      columnInstance.map((column) => ({
        ...column,
        filterParams: {
          ...column.filterParams,
          applyMiniFilterWhileTyping: true,
          showTooltips: true,
        },
      })),
    [],
  );

  const handleVerticalWeekColumns = React.useCallback((columnInstance) => {
    const hasWeekVerticalColumns = columnInstance.some(
      (column) => column.field?.includes('week') && column.field?.includes('-vertical')
    );
    setGridHasVerticalWeekColumns(hasWeekVerticalColumns);
    if (!hasWeekVerticalColumns) return columnInstance;

    return columnInstance.map((column) => {
      if (column.field?.includes('week') && column.field?.includes('-vertical')) {
        column['headerClass'] = 'ag-header-week-vertical';
      } else {
        column['headerClass'] = 'ag-header-none-vertical';
      }

      return column;
    });
  }, []);

  const handleColumnProperties = React.useCallback(() => {
    const columnInstance = columns;
    const functionsToApply = [
      handleBooked,
      handleCapitalization,
      handleChildColumnHeaders,
      handleSharedColumnProperties,
      handleColumnHeaderTooltips,
      handleSetClearFilterAtLastColumn,
      handleColumnFilterParams,
      handleVerticalWeekColumns,
    ];
    const modifiedColumnDefinition = functionsToApply.reduce((column, func) => func(column), columnInstance);
    setColumnDefinition(modifiedColumnDefinition);
    // TODO: Create overall loader for datagrid
    setGridIsLoading(false);
  }, [
    columns,
    columnDefinition,
    handleBooked,
    handleCapitalization,
    handleChildColumnHeaders,
    handleSharedColumnProperties,
    handleColumnHeaderTooltips,
    handleSetClearFilterAtLastColumn,
    handleColumnFilterParams,
    handleVerticalWeekColumns,
  ]);

  const handleOnSelectionChanged = React.useCallback(() => {
    // Added to handle selected rows count
    const rows = mainGridRef.current.api.getSelectedNodes();
    if (onSelectionChanged) onSelectionChanged(rows);
    mainGridRef.current.api.refreshCells({ force: true }); // this line allows the cell color to go back to it's intial color setting after being deselected
  }, []);

  const handleOnMainGridReady = (params) => {
    if (onGridReady) onGridReady(params);
    if (columnIsWithChildren) {
      params.api.setGridOption('headerHeight', '30px');
    } else {
      params.api.setGridOption('headerHeight', gridHasVerticalWeekColumns ? '110px' : '50px');
    }
  };

  const handleAutoGroupColumnProperties = React.useCallback(() => {
    // check if property is defined
    if (!Object.keys(autoGroupColumnDef).length) return;
    const hasWeekVerticalColumns = columns.some(
      (column) => column.field?.includes('week') && column.field?.includes('-vertical')
    );

    setAutoGroupColumnDefinition(() => ({
      ...autoGroupColumnDef,
      headerComponent: AutoGroupHeader,
      headerClass: `${autoGroupColumnDef.headerClass || ''} ${hasWeekVerticalColumns ? 'ag-header-none-vertical' : ''}`,
      ...(autoGroupColumnDef?.headerTooltip ? {} : { headerTooltip: autoGroupColumnDef.headerName }),
      ...(autoGroupColumnDef?.tooltipValueGetter ? {} : { tooltipValueGetter: (params) => params.value }),
      filterParams: {
        ...autoGroupColumnDef?.filterParams,
        ...(autoGroupColumnDef?.applyMiniFilterWhileTyping ? {} : { applyMiniFilterWhileTyping: true }),
      },
    }));
  }, [autoGroupColumnDef, setAutoGroupColumnDefinition]);

  React.useEffect(() => {
    // Get total pixels to subtract to total height to fit viewport.
    // Get total pixels to subtract to total height to fit viewport.
    const totalPixelsInHeight = () => {
      let totalHeight = 0;

      if (pagination) {
        totalHeight += 181; // add 181px if default pagination is enabled
      } else {
        totalHeight += 231; // add 231px if custom footer is enabled
      }

      // Checks if bread-crumb/route exists on the page and get its height
      if (document.getElementById('bread-crumb')) {
        const breadCrumbCurrent = document.getElementById('bread-crumb');
        // Breadcrumb height + 20px (from gap)
        totalHeight += breadCrumbCurrent.offsetHeight + 20;
      }

      // Checks if ProgramTimeSelector in Program Filter Listings exists on the page and get its height (Temporary workaround)
      if (document.getElementById('program-time-selector')) {
        const programTimeSelectorCurrent = document.getElementById('program-time-selector');
        // ProgramTimeSelector height + 10px (from gap)
        totalHeight += programTimeSelectorCurrent.offsetHeight + 10;
      }

      return totalHeight;
    };
    const height = totalPixelsInHeight() - gridHeightBuffer;
    setGridHeightFromRoot(height);
  }, [gridHeightBuffer]);

  React.useEffect(() => {
    let timeoutId;

    if (!gridIsLoading) {
      timeoutId = setTimeout(() => setIsMainGridReady(true), 200);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [gridIsLoading, setIsMainGridReady]);

  React.useEffect(() => {
    handleColumnProperties();
    mainGridRef.current?.api?.setGridOption('columnDefs', columnDefinition);
  }, [columns]);

  React.useEffect(() => {
    handleAutoGroupColumnProperties();
    mainGridRef.current?.api?.setGridOption('autoGroupColumnDef', autoGroupColumnDefinition);
  }, [autoGroupColumnDef]);

  React.useEffect(() => {
    // Hides the noRowsOverlayComponent when grid is or rows are being loaded
    // Prevents overlapping with BootstrapSpinner
    if (!isMainGridReady || loading) {
      mainGridRef.current?.api.hideOverlay();
    }
  }, [loading, isMainGridReady]);

  const handleOnFilterChanged = React.useCallback((params) => {
    setRowsToDisplay(params.api.rowModel.rowsToDisplay.length);
    mainGridRef.current.api.refreshCells({ force: true });
  }, []);

  const handleOnSortChanged = React.useCallback(() => {
    mainGridRef.current.api.refreshCells({ force: true });
  }, []);

  const handleOnRowDataUpdated = (params) => {
    // eslint-disable-next-line no-unused-expressions
    onRowDataUpdated && onRowDataUpdated(params);
  };

  return (
    <div
      className={`${
        columnIsWithChildren ? 'ag-theme-op2mise-2' : 'ag-theme-op2mise'
      } customGrid`}
      style={dataGridStyle}
    >
      {(!isMainGridReady || loading) && (
        // Shows grid loader for a milli-second to allow data-grid to fully render first before showing
        <div
          style={{
            width: '100%',
            height: dataGridStyle.height,
            zIndex: 2,
            borderRadius: '6px',
            background: 'var(--op2mise-color-white)',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <BootstrapSpinner />
          </div>
        </div>
      )}
      {!gridIsLoading && (
        <AgGridReact
          alignedGrids={[overviewGridRef]}
          alwaysShowVerticalScroll
          autoGroupColumnDef={autoGroupColumnDefinition}
          columnDefs={columnDefinition}
          defaultColDef={defaultColumnProperties}
          noRowsOverlayComponent={noRowsOverlayComponent}
          onFilterChanged={handleOnFilterChanged}
          onGridReady={handleOnMainGridReady}
          // onRowGroupOpened={() => setIsVerticalScroll(isVerticalScrollDisplayed())}
          onRowDataUpdated={handleOnRowDataUpdated}
          onRowGroupOpened={(params) => {
            const isExpanded = params.api.rowModel.rowsToDisplay.find(
              (obj) => obj.expanded
            );
            if (isExpanded) {
              setIsVerticalScroll(true);
            } else {
              setIsVerticalScroll(false);
            }
          }}
          onSelectionChanged={handleOnSelectionChanged}
          onSortChanged={handleOnSortChanged}
          pagination={pagination}
          paginationPageSize={pageSize}
          ref={mainGridRef}
          reactiveCustomComponents
          rowData={rowData}
          rowClassRules={rowClassRulesMemo}
          suppressScrollOnNewData
          rowSelection={enableMultipleRowSelection ? 'multiple' : 'single'}
          rowMultiSelectWithClick={enableMultipleRowSelection}
          suppressMovableColumns={suppressMovableColumns}
          suppressRowClickSelection={
            suppressRowClickSelection || useCheckboxForRowSelection
          }
          suppressChangeDetection
          {...(enableMultipleColumnSorting && { multiSortKey: 'ctrl' })}
          {...grid.main.DEFAULT}
          {...mainGridRestProps}
        />
      )}
    </div>
  );
}
